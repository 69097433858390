import React, { useCallback, useRef } from 'react';
import { useEditorState } from '../../core/hooks';

type TimeSlot = {
  [key: number]: { key: string; cb: () => void; runOnce: boolean; ran?: boolean }[];
};

// https://codesandbox.io/s/epic-bird-4g4t7y?file=/src/App.tsx
export function useGlobalTimer() {
  const { disabled } = useEditorState();
  const timeIndexRef = React.useRef(0);
  const timeSlotsRef = useRef<TimeSlot>({});
  const currentTime = React.useRef(performance.now());
  const hasTimeSlots = Object.keys(timeSlotsRef.current).length > 0;

  // Add a new callback to a timeslot
  const registerForTimeslot = useCallback(
    (key: string, time: number, cb: () => void, runOnce?: boolean) => {
      const timeIndex = Math.floor(time / 10);
      console.log('⏰ Register for slot', time, `${runOnce ? '(runOnce)' : ''}`);
      const timeSlots = timeSlotsRef.current;

      const slot = timeSlots[timeIndex] || (timeSlots[timeIndex] = []);

      // Don't register same callback twice
      if (slot.find((s) => s.key === key)) return;

      slot.push({ key, cb, runOnce: !!runOnce });
    },
    [],
  );

  const clearTimeslots = useCallback(() => {
    // Clear timeslots except runOnce timers that has already run, to prevent them running more than once.
    for (const key in timeSlotsRef.current) {
      timeSlotsRef.current[key] = timeSlotsRef.current[key].filter((s) => {
        return s.runOnce && s.ran;
      });
      if (timeSlotsRef.current[key].length === 0) delete timeSlotsRef.current[key];
    }
  }, []);

  React.useEffect(() => {
    if (!disabled && hasTimeSlots) {
      console.log('⏰ Starting interval timer!');
      currentTime.current = performance.now();
      timeIndexRef.current = 1;

      // Tick!
      const onFrame = () => {
        const timeIndex = timeIndexRef.current++;
        const timeSlots = timeSlotsRef.current;

        for (const key in timeSlots) {
          const index = parseInt(key);
          if (timeIndex % index === 0) {
            const slot = timeSlots[key];
            for (const s of slot) {
              if (s.ran) continue;
              s.cb();
              if (s.runOnce && !s.ran) {
                console.log('⏰ Removing callback after runOnce!');
                s.ran = true;
              }
            }
          }
        }
      };

      const interval = setInterval(onFrame, 10);
      return () => {
        console.log('⏰ Stopping timer!');
        clearTimeout(interval);
      };
    }
  }, [disabled, hasTimeSlots]);

  return {
    registerForTimeslot,
    clearTimeslots,
  };
}
