import { Variable } from './types';

interface ModelVariablePath {
  modelKey: string;
  path: string[];
}

export type ModelVariables = Record<string, ModelVariablePath[]>;

/**
 * Iterate over all model content to find variable content
 * @param initialVariables
 * @param states
 */
export function getStateVariables(initialVariables: Variable[], states: any): ModelVariables {
  const modelVariables: ModelVariables = {};
  const variableMap: Record<string, Variable> = {};
  initialVariables.forEach((variable) => {
    variableMap[`var(--vev-${variable.key})`] = variable;
  });

  if (states) {
    Object.keys(states).forEach((stateKey) => {
      const state = states[stateKey];
      state.models.forEach((model: any) => {
        if (model.content) {
          getModelVariables(model.key, model.content, variableMap, [], modelVariables);
        }
      });
    });
  }

  return modelVariables;
}

/**
 * Recursively find variable content in a model
 * @param model
 * @param variableMap
 * @param currentPath
 * @param modelVariables
 */
function getModelVariables(
  modelKey: string,
  modelContent: any,
  variableMap: Record<string, Variable>,
  currentPath: string[],
  modelVariables: ModelVariables,
): ModelVariables | null {
  Object.keys(modelContent).forEach((contentAttr) => {
    if (contentAttr === 'children') return null;
    const modelElement = modelContent[contentAttr];
    if (typeof modelElement === 'string') {
      if (variableMap[modelElement]) {
        currentPath.push(contentAttr);
        if (modelVariables[modelElement]) {
          modelVariables[variableMap[modelElement].key].push({ modelKey, path: currentPath });
        } else {
          modelVariables[variableMap[modelElement].key] = [{ modelKey, path: currentPath }];
        }
      }
    }
    if (typeof modelElement === 'object' && !Array.isArray(modelElement) && modelElement !== null) {
      currentPath.push(contentAttr);
      getModelVariables(modelKey, modelElement, variableMap, currentPath, modelVariables);
    }
  });

  return modelVariables;
}
