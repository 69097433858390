import { Variable } from './types';
import { getStateVariables, ModelVariables } from './get-state-variables';
import { attributePathToObject } from './attribute-path-to-object';
import { isEqual } from 'lodash';

declare global {
  interface Window {
    variable: VariableManagerImpl;
    variable_dbg: boolean;
    dispatch: any;
  }
}

let instance: VariableManagerImpl;

class VariableManagerImpl {
  private variables: Map<string, Variable> = new Map();
  private subscriptions: Map<string, ((change: Variable) => void)[]> = new Map();
  private stateVariables: ModelVariables = {};
  private initialVariables: Variable[] = [];
  private windowRef: Window;

  constructor() {
    this.log('Initialized VariableManager');
    this.windowRef = window;
  }
  initializeVariables(initialVariables: Variable[]): void {
    this.log('Initialize Variables', initialVariables);
    this.variables = new Map();
    this.initialVariables = initialVariables;
    initialVariables.forEach((variable) => {
      this.setVariable(variable.key, variable);
    });
    this.updateStateVariables();
  }

  bindWindow(window: Window): void {
    this.windowRef = window;
  }

  updateStateVariables(): void {
    // @ts-expect-error - Works
    const states = this.windowRef.vevs || this.windowRef.vevStates;

    const newModelVariables = getStateVariables(this.initialVariables, states);
    Object.keys(newModelVariables).forEach((variableKey) => {
      const updatedModelVariables = newModelVariables[variableKey];
      updatedModelVariables.forEach((updatedModelVariable) => {
        Object.keys(this.stateVariables).forEach((key, index) => {
          this.stateVariables[key] = this.stateVariables[key].filter((stateVariable) => {
            return !isEqual(stateVariable, updatedModelVariable);
          });
        });
      });
    });

    Object.keys(newModelVariables).forEach((variableKey) => {
      if (this.stateVariables[variableKey]) {
        this.stateVariables[variableKey] = [
          ...this.stateVariables[variableKey],
          ...newModelVariables[variableKey],
        ];
      } else {
        this.stateVariables[variableKey] = [...newModelVariables[variableKey]];
      }
    });

    if (Object.keys(newModelVariables).length) {
      Object.keys(this.stateVariables).forEach((variableKey) => {
        const variable = this.variables.get(variableKey);
        if (variable) {
          this.dispatchContentChanges(variable);
        }
      });
    }
  }

  resetSubscriptions() {
    this.log('Reset subscriptions');
    this.subscriptions = new Map();
  }

  setVariable(key: string, variable: Partial<Variable>) {
    this.log('Set variable', variable);
    const prev = this.variables.get(key);
    if (prev) {
      const update = { ...prev, ...variable };
      this.variables.set(key, update);
      this.dispatchChange(update);
    } else {
      this.variables.set(key, variable as Variable);
      this.dispatchChange(variable as Variable);
    }
  }

  getVariable(key: string) {
    return this.variables.get(key);
  }

  subscribe(keys: string[], cb: (change: Variable) => void) {
    this.log('New subscription', keys);
    keys.forEach((key) => {
      const subscription = this.subscriptions.get(key);
      if (!subscription) {
        this.subscriptions.set(key, [cb]);
      } else {
        subscription.push(cb);
      }
    });
  }

  unsubscribe(keys: string[], cb: (change: Variable) => void) {
    keys.forEach((key) => {
      const subscription = this.subscriptions.get(key);
      if (subscription) {
        const filteredSubs = subscription.filter((sub) => {
          return sub !== cb;
        });
        if (filteredSubs.length) {
          this.subscriptions.set(key, filteredSubs);
        } else {
          this.subscriptions.delete(key);
        }
      }
    });
  }

  private dispatchChange(update: Variable) {
    const subscriptions = this.subscriptions.get(update.key);
    if (subscriptions) {
      subscriptions.forEach((subscription) => {
        this.log('Dispatch subscription change', update);
        subscription(update);
      });
    }

    // Update CSS variables
    if (update.type !== 'text') {
      document.documentElement.style.setProperty(`--vev-${update.key}`, update.value);
    }

    this.dispatchContentChanges(update);
  }

  private dispatchContentChanges(update: Variable) {
    // Update content variables
    if (this.stateVariables[update.key]) {
      this.stateVariables[update.key].forEach((modelVariable) => {
        const updateObject = attributePathToObject(modelVariable.path, update.value);
        Object.keys(window.dispatch).forEach((stateKey: string) => {
          const dispatch = window.dispatch[stateKey];
          dispatch('widget-content', {
            modelKey: modelVariable.modelKey,
            content: updateObject,
          });
        });
      });
    }
  }

  private log(...data: any[]) {
    if (window.variable_dbg) {
      console.log('🟢 VariableManager | ', ...data);
    }
  }
}

export const VariableManager = {
  getInstance() {
    if (!instance) {
      window.variable_dbg = false;
      instance = new VariableManagerImpl();
      window.variable = instance;
    }
    return instance;
  },
};
