import {
  GlobalTimer,
  attachGlobalListeners,
  convertToDomEventType,
  dispatchExternalTrackingEvent,
  getOuterNodeId,
  isNativeEvent,
  isShareEvent,
  isTargetVariable,
  isTrackingEvent,
  isTriggerGlobal,
  triggerShareDialog,
  evaluateEventCondition,
} from './utils';
import {
  DOM_TRIGGER_TYPES,
  VevDispatchEvent,
  VevTriggerType,
  InteractionMap,
  ProjectInteraction,
} from './types';
import { VevInteractionsNode } from './interactions-node';

export type EventBrokerCallback = (args?: Record<string, string>) => void;
export type EventBrokerCallbackRecord = Map<string, EventBrokerCallback>;
export type InteractionsManagerArgs = {
  interactions: InteractionMap;
  config: { disabled: boolean; debug: boolean };
};

//                     ▄░▀▀▀▀░▄
//              ██    █░██▀██░█▄    Jasså?!?
//               █      ▀▄ ▀ ▄▀
//               █▄▄▄▄   ████

const logger = (...data: any[]) => console.log('🔵 InteractionsManager -> ', ...data);

/**
 * initInteractionsManager
 *
 * @param interactions  InteractionMap  - Map of interactions for the page
 * @param debug         boolean         - Enable/disable logging
 * @param disabled      boolean         - Disable actions (in editor mode)
 * @returns destroy()   function        - Function to destroy the manager
 */
export function initInteractionsManager(args: InteractionsManagerArgs) {
  if (window && (window as any).vevInteractionsManager) {
    (window as any).vevInteractionsManager.update(args);
    return;
  }

  const manager = new VevInteractionsManager(args);

  // Attach to window and expose to contexts
  if (window) (window as any).vevInteractionsManager = manager;
  if (document) document.documentElement.setAttribute('data-vev-interactions', 'true');

  return () => {
    manager.destroy();
  };
}

/**
 * VevInteractionsManager Class
 *
 * -> Keep track of all interactions on the page
 * -> Listen for VEV events
 * -> Trigger callbacks
 */
export class VevInteractionsManager {
  private interactions: InteractionMap;
  private records: EventBrokerCallbackRecord;
  private nodes: Map<string, VevInteractionsNode>;
  private debug: (...data: any[]) => void;
  private disabled: boolean;
  private timer: GlobalTimer;

  constructor(args: InteractionsManagerArgs) {
    this.interactions = args.interactions;
    this.timer = new GlobalTimer();
    this.records = new Map();
    this.nodes = new Map();
    this.debug = args.config.debug ? logger : () => {};
    this.disabled = args.config.disabled;

    this.debug(`Initialised${this.disabled ? ' (Disabled)' : ''}`);

    if (window) (window as any).addEventListener('@@vev', this.handleEvent as EventListener);
    if (window) this.reloadTimer();
    if (window) this.attachGlobalTriggers();
    if (window) this.attachVariableListeners();
  }

  private attachGlobalTriggers = () => {
    attachGlobalListeners();
  };

  public update = (args: InteractionsManagerArgs) => {
    this.debug('Updating');
    this.interactions = args.interactions;
    this.disabled = args.config.disabled;
    this.reloadTimer();
  };

  private reloadTimer = () => {
    this.timer.stopTimer();
    this.timer.clearTimeslots();

    // Find all project interactions with a timer trigger
    const timerInteractions = this.interactions?.trigger?.global?.onTimer || [];

    // Register all timer interactions
    timerInteractions.forEach((interaction) => {
      if (interaction?.deleted) return;
      const delayValue = interaction.trigger?.condition?.split('=')[1];
      const delay = delayValue ? parseInt(delayValue) : 1000;
      const runOnce = interaction.trigger?.condition?.includes('timeout');

      this.timer.registerForTimeslot(
        `${interaction?.event?.type}.${interaction?.event?.contentKey}`,
        delay,
        () => {
          const callbackName = `${interaction?.event?.type}.${interaction?.event?.contentKey}`;
          const callback = this.records.get(callbackName);
          const isDisabled = interaction?.disabled;

          if (callback && !isDisabled) {
            callback((interaction.event?.args || {}) as any);
          }
        },
        !!runOnce,
      );
    });

    if (!this.disabled) this.timer.startTimer();
  };

  // Register all variable interactions
  private attachVariableListeners = () => {
    // Find all project interactions with a variable event
    Object.keys(this.interactions.event.widget).forEach((key) => {
      const widgetInteractions = this.interactions.event.widget[key];
      widgetInteractions.forEach((interaction) => {
        if (isTargetVariable(interaction?.event?.type)) {
          if (interaction?.event?.contentKey) {
            const callbackName = `${interaction.event.type}.${interaction.event.contentKey}`;
            this.records.set(callbackName, (args) => {
              const actualArgs = args || interaction.event?.args;
              if (interaction.event && (window as any)?.variable) {
                (window as any).variable.setVariable(interaction.event.contentKey, {
                  value: actualArgs?.value,
                });
              }
            });
          }
        }
      });
    });
  };

  /**
   * handleEvent - Receive global VEV events and trigger relevant callbacks
   * @param event CustomEvent<VevDispatchEvent>
   */
  private handleEvent = (event: CustomEvent<VevDispatchEvent>) => {
    if (this.disabled) {
      this.debug('Disabled - Ignoring event');
      return;
    }

    this.debug('→ Received Event', event.detail);

    // Array of relevant interactions
    const matches: ProjectInteraction[] = [];

    // If the event has a contentKey, add all interactions for that contentKey
    if (event.detail.contentKey) {
      // If the contentKey is 12 characters long, it's triggered by a child widget
      if (event.detail.contentKey.length === 12) {
        const parentKey = getOuterNodeId(event.detail.contentKey);
        matches.push(...(this.interactions.trigger?.widget?.[parentKey] || []));
      } else {
        matches.push(...(this.interactions.trigger?.widget?.[event.detail.contentKey] || []));
      }
    }

    // If the event is a global trigger, add interactions for that global trigger type
    if (event.detail.type && isTriggerGlobal(event.detail.type)) {
      matches.push(...(this.interactions.trigger?.global?.[event.detail.type] || []));
    }

    // Loop through matches and fire callback if condition is met
    matches
      .filter((i) => {
        if (isNativeEvent(i.trigger?.type || '')) {
          return event.detail.type === convertToDomEventType(i.trigger?.type as DOM_TRIGGER_TYPES);
        }
        return event.detail.type === i.trigger?.type;
      })
      .forEach((interaction) => {
        let contentKey = interaction?.event?.contentKey;
        const chain = event.detail.instanceKeyChain;

        // Check if the event is for a main component
        if (chain && !contentKey?.includes(chain)) {
          contentKey = `${contentKey}${chain || ''}`;
        }

        const callbackName = `${interaction?.event?.type}.${contentKey}`;
        const callback = this.records.get(callbackName);
        const isDisabled = interaction?.disabled;

        if (callback && !isDisabled) {
          const args = interaction.event?.args || event.detail.args;
          if (interaction?.trigger?.condition) {
            const success = evaluateEventCondition(
              interaction?.trigger?.condition,
              event.detail.args,
            );
            if (success) {
              this.debug('➡️ Relaying event: ', event.detail, args);
              callback(args);
            }
          } else {
            this.debug('➡️ Relaying event: ', event.detail, args);
            callback(args);
          }
        }

        // Handle tracking events
        if (isTrackingEvent(interaction.event?.type || '') && !isDisabled) {
          // Dispatch custom event for tracking
          dispatchExternalTrackingEvent(interaction.event?.args);
        }

        // Handle share events
        if (isShareEvent(interaction.event?.type || '') && !isDisabled) {
          triggerShareDialog(interaction.event?.args);
        }
      });
  };

  /**
   * addNode - Register a node with the manager
   * @param rootNode VevInteractionsNode
   * @returns void
   */
  public addNode = (id: string, rootNode: VevInteractionsNode) => {
    this.debug('Adding node', id);
    this.nodes.set(id, rootNode);
  };

  // Check if node is registered
  public getNode = (id: string) => {
    return this.nodes.get(id);
  };

  /**
   * addCallback - Add a callback to the manager
   * @param event VevTriggerType
   * @param contentKey string
   * @param callback EventBrokerCallback
   * @returns void
   */
  public addCallback = (
    event: VevTriggerType,
    contentKey: string | undefined,
    callback: EventBrokerCallback,
  ) => {
    if (event && contentKey) {
      this.debug('Adding callback', event, contentKey);
      this.records.set(`${event}.${contentKey}`, callback);
    }
  };

  /**
   * removeCallback - Remove a callback from the manager
   * @param event VevTriggerType
   * @param contentKey string
   * @returns void
   */
  public removeCallback = (event: VevTriggerType, contentKey: string | undefined) => {
    if (event && contentKey) {
      this.debug('Removing callback', event, contentKey);
      this.records.delete(`${event}.${contentKey}`);
    }
  };

  /**
   * destroy - Remove event listener
   * @returns void
   */
  public destroy = () => {
    // Remove event listener
    window.removeEventListener('@@vev', this.handleEvent as EventListener);
  };
}
