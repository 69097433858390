export function attributePathToObject(paths: string[], value: string): Record<string, any> {
  const result: Record<string, any> = {};
  let current = result;
  paths.forEach((path, index) => {
    if (index === paths.length - 1) {
      current[path] = value;
    } else {
      current[path] = {};
      current = current[path];
    }
  });
  return result;
}
