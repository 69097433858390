type TimeSlot = {
  [key: number]: { key: string; cb: () => void; runOnce: boolean; ran?: boolean }[];
};

export class GlobalTimer {
  private timeIndex: number;
  private timeSlots: TimeSlot;
  private currentTime: number;
  private interval: NodeJS.Timeout | null;

  constructor() {
    this.timeIndex = 0;
    this.timeSlots = {};
    this.currentTime = performance.now();
    this.interval = null;
  }

  // Add a new callback to a timeslot
  public registerForTimeslot = (key: string, time: number, cb: () => void, runOnce?: boolean) => {
    const timeIndex = Math.floor(time / 10);
    console.log('⏰ Register for slot', time, `${runOnce ? '(runOnce)' : ''}`);
    const slot = this.timeSlots[timeIndex] || (this.timeSlots[timeIndex] = []);

    // Don't register same callback twice
    if (slot.find((s) => s.key === key)) return;

    slot.push({ key, cb, runOnce: !!runOnce });
  };

  public clearTimeslots = () => {
    // Clear timeslots except runOnce timers that has already run, to prevent them running more than once.
    for (const key in this.timeSlots) {
      this.timeSlots[key] = this.timeSlots[key].filter((s) => {
        return s.runOnce && s.ran;
      });
      if (this.timeSlots[key].length === 0) delete this.timeSlots[key];
    }
  };

  public startTimer = () => {
    // Only start if there are timeslots
    if (Object.keys(this.timeSlots).length > 0) {
      console.log('⏰ Starting interval timer!');
      this.currentTime = performance.now();
      this.timeIndex = 1;

      // Tick!
      const onFrame = () => {
        const timeIndex = this.timeIndex++;
        const { timeSlots } = this;

        for (const key in timeSlots) {
          const index = parseInt(key);
          if (timeIndex % index === 0) {
            const slot = timeSlots[key];
            for (const s of slot) {
              if (s.ran) continue;
              s.cb();
              if (s.runOnce && !s.ran) {
                console.log('⏰ Removing callback after runOnce!');
                s.ran = true;
              }
            }
          }
        }
      };

      this.interval = setInterval(onFrame, 10);
    }
  };

  public stopTimer = () => {
    if (this.interval) {
      console.log('⏰ Stopping timer!');
      clearInterval(this.interval);
      this.interval = null;
    }
  };
}
